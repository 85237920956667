export default {
    vpadNone: Boolean,
    vpadHalf: Boolean,
    vpadDefault: Boolean,
    vpadDouble: Boolean,
    vpadTriple: Boolean,
    vpadQuadruple: Boolean,

    vpadXsNone: Boolean,
    vpadXsHalf: Boolean,
    vpadXsDefault: Boolean,
    vpadXsDouble: Boolean,
    vpadXsTriple: Boolean,
    vpadXsQuadruple: Boolean,

    vpadSmNone: Boolean,
    vpadSmHalf: Boolean,
    vpadSmDefault: Boolean,
    vpadSmDouble: Boolean,
    vpadSmTriple: Boolean,
    vpadSmQuadruple: Boolean,

    vpadMdNone: Boolean,
    vpadMdHalf: Boolean,
    vpadMdDefault: Boolean,
    vpadMdDouble: Boolean,
    vpadMdTriple: Boolean,
    vpadMdQuadruple: Boolean,

    vpadLgNone: Boolean,
    vpadLgHalf: Boolean,
    vpadLgDefault: Boolean,
    vpadLgDouble: Boolean,
    vpadLgTriple: Boolean,
    vpadLgQuadruple: Boolean,

    vpadXlNone: Boolean,
    vpadXlHalf: Boolean,
    vpadXlDefault: Boolean,
    vpadXlDouble: Boolean,
    vpadXlTriple: Boolean,
    vpadXlQuadruple: Boolean,

    tpadNone: Boolean,
    tpadHalf: Boolean,
    tpadDefault: Boolean,
    tpadDouble: Boolean,
    tpadTriple: Boolean,
    tpadQuadruple: Boolean,

    tpadXsNone: Boolean,
    tpadXsHalf: Boolean,
    tpadXsDefault: Boolean,
    tpadXsDouble: Boolean,
    tpadXsTriple: Boolean,
    tpadXsQuadruple: Boolean,

    tpadSmNone: Boolean,
    tpadSmHalf: Boolean,
    tpadSmDefault: Boolean,
    tpadSmDouble: Boolean,
    tpadSmTriple: Boolean,
    tpadSmQuadruple: Boolean,

    tpadMdNone: Boolean,
    tpadMdHalf: Boolean,
    tpadMdDefault: Boolean,
    tpadMdDouble: Boolean,
    tpadMdTriple: Boolean,
    tpadMdQuadruple: Boolean,

    tpadLgNone: Boolean,
    tpadLgHalf: Boolean,
    tpadLgDefault: Boolean,
    tpadLgDouble: Boolean,
    tpadLgTriple: Boolean,
    tpadLgQuadruple: Boolean,

    tpadXlNone: Boolean,
    tpadXlHalf: Boolean,
    tpadXlDefault: Boolean,
    tpadXlDouble: Boolean,
    tpadXlTriple: Boolean,
    tpadXlQuadruple: Boolean,

    bpadNone: Boolean,
    bpadHalf: Boolean,
    bpadDefault: Boolean,
    bpadDouble: Boolean,
    bpadTriple: Boolean,
    bpadQuadruple: Boolean,

    bpadXsNone: Boolean,
    bpadXsHalf: Boolean,
    bpadXsDefault: Boolean,
    bpadXsDouble: Boolean,
    bpadXsTriple: Boolean,
    bpadXsQuadruple: Boolean,

    bpadSmNone: Boolean,
    bpadSmHalf: Boolean,
    bpadSmDefault: Boolean,
    bpadSmDouble: Boolean,
    bpadSmTriple: Boolean,
    bpadSmQuadruple: Boolean,

    bpadMdNone: Boolean,
    bpadMdHalf: Boolean,
    bpadMdDefault: Boolean,
    bpadMdDouble: Boolean,
    bpadMdTriple: Boolean,
    bpadMdQuadruple: Boolean,

    bpadLgNone: Boolean,
    bpadLgHalf: Boolean,
    bpadLgDefault: Boolean,
    bpadLgDouble: Boolean,
    bpadLgTriple: Boolean,
    bpadLgQuadruple: Boolean,

    bpadXlNone: Boolean,
    bpadXlHalf: Boolean,
    bpadXlDefault: Boolean,
    bpadXlDouble: Boolean,
    bpadXlTriple: Boolean,
    bpadXlQuadruple: Boolean,
}