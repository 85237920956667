export default {
    hpadNone: Boolean,
    hpadHalf: Boolean,
    hpadDefault: Boolean,
    hpadDouble: Boolean,
    hpadTriple: Boolean,
    hpadQuadruple: Boolean,

    hpadXsNone: Boolean,
    hpadXsHalf: Boolean,
    hpadXsDefault: Boolean,
    hpadXsDouble: Boolean,
    hpadXsTriple: Boolean,
    hpadXsQuadruple: Boolean,

    hpadSmNone: Boolean,
    hpadSmHalf: Boolean,
    hpadSmDefault: Boolean,
    hpadSmDouble: Boolean,
    hpadSmTriple: Boolean,
    hpadSmQuadruple: Boolean,

    hpadMdNone: Boolean,
    hpadMdHalf: Boolean,
    hpadMdDefault: Boolean,
    hpadMdDouble: Boolean,
    hpadMdTriple: Boolean,
    hpadMdQuadruple: Boolean,

    hpadLgNone: Boolean,
    hpadLgHalf: Boolean,
    hpadLgDefault: Boolean,
    hpadLgDouble: Boolean,
    hpadLgTriple: Boolean,
    hpadLgQuadruple: Boolean,

    hpadXlNone: Boolean,
    hpadXlHalf: Boolean,
    hpadXlDefault: Boolean,
    hpadXlDouble: Boolean,
    hpadXlTriple: Boolean,
    hpadXlQuadruple: Boolean,

    lpadNone: Boolean,
    lpadHalf: Boolean,
    lpadDefault: Boolean,
    lpadDouble: Boolean,
    lpadTriple: Boolean,
    lpadQuadruple: Boolean,

    lpadXsNone: Boolean,
    lpadXsHalf: Boolean,
    lpadXsDefault: Boolean,
    lpadXsDouble: Boolean,
    lpadXsTriple: Boolean,
    lpadXsQuadruple: Boolean,

    lpadSmNone: Boolean,
    lpadSmHalf: Boolean,
    lpadSmDefault: Boolean,
    lpadSmDouble: Boolean,
    lpadSmTriple: Boolean,
    lpadSmQuadruple: Boolean,

    lpadMdNone: Boolean,
    lpadMdHalf: Boolean,
    lpadMdDefault: Boolean,
    lpadMdDouble: Boolean,
    lpadMdTriple: Boolean,
    lpadMdQuadruple: Boolean,

    lpadLgNone: Boolean,
    lpadLgHalf: Boolean,
    lpadLgDefault: Boolean,
    lpadLgDouble: Boolean,
    lpadLgTriple: Boolean,
    lpadLgQuadruple: Boolean,

    lpadXlNone: Boolean,
    lpadXlHalf: Boolean,
    lpadXlDefault: Boolean,
    lpadXlDouble: Boolean,
    lpadXlTriple: Boolean,
    lpadXlQuadruple: Boolean,

    rpadNone: Boolean,
    rpadHalf: Boolean,
    rpadDefault: Boolean,
    rpadDouble: Boolean,
    rpadTriple: Boolean,
    rpadQuadruple: Boolean,

    rpadXsNone: Boolean,
    rpadXsHalf: Boolean,
    rpadXsDefault: Boolean,
    rpadXsDouble: Boolean,
    rpadXsTriple: Boolean,
    rpadXsQuadruple: Boolean,

    rpadSmNone: Boolean,
    rpadSmHalf: Boolean,
    rpadSmDefault: Boolean,
    rpadSmDouble: Boolean,
    rpadSmTriple: Boolean,
    rpadSmQuadruple: Boolean,

    rpadMdNone: Boolean,
    rpadMdHalf: Boolean,
    rpadMdDefault: Boolean,
    rpadMdDouble: Boolean,
    rpadMdTriple: Boolean,
    rpadMdQuadruple: Boolean,

    rpadLgNone: Boolean,
    rpadLgHalf: Boolean,
    rpadLgDefault: Boolean,
    rpadLgDouble: Boolean,
    rpadLgTriple: Boolean,
    rpadLgQuadruple: Boolean,

    rpadXlNone: Boolean,
    rpadXlHalf: Boolean,
    rpadXlDefault: Boolean,
    rpadXlDouble: Boolean,
    rpadXlTriple: Boolean,
    rpadXlQuadruple: Boolean,
}